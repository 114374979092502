import Component from '../core/Component';

export const STATUS = {
	HIDDEN: 'is-hidden',
}

export default class ToggleHashtags extends Component {
	constructor(element) {
		super(element);

		this.ref = {
			items: [],
			button: null
		}
	}

	prepare() {
		this.ref.button.addEventListener('click', :: this.handleClick)
	}

	handleClick(event) {
		event.preventDefault()

		this.ref.items.forEach(item => item.classList.remove(STATUS.HIDDEN))
		this.ref.button.classList.add(STATUS.HIDDEN)
	}
}
