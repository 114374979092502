import Component from '../core/Component'

const ANCHOR_SELECTOR = 'a[class*="color-"]'

export default class Page extends Component {
    constructor(element) {
        super(element)

        this.overlay = document.querySelector('.Overlay')
    }

    prepare() {
        this.element.addEventListener('click', ::this.handleClick)
    }

    handleClick(event) {
        const target = event.target.closest(ANCHOR_SELECTOR)
        let color

        if (target) {
            for (var i = 0; i < target.classList.length; i++) {
                if (target.classList[i].includes('color-')) {
                    color = target.classList[i]
                }
            }

            this.overlay.classList.add(color)

            setTimeout(() => {
                this.overlay.classList.remove(color)
            }, 1000)
        }
    }
}