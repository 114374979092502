import Component from '../core/Component'
import throttle from 'lodash/throttle'
import Scroll, { getScrollTop } from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const STATES = {
    STICKY: 'is-sticky'
}

export default class Sticky extends Component {
    constructor(element, options = { top: 120, media: 'xlargeUp' }) {
        super(element, {})

        const top = parseInt(this.element.dataset.stickyTop)
        const social = this.element.dataset.type

        if (!isNaN(top) && !social) {
            this.options.top = top
        }
        this.options = options
        this.enquireHandler = null

        this.isSticky = false
        this.social = false

        if (social) {
            this.social = true
        }
        this.handleScroll = :: this.handleScroll
        this.handleResize = throttle(:: this.handleResize, 100)

        if (this.options.media) {
            this.enquireQuery = this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: :: this.attach,
                unmatch: :: this.detach
            }
        }
    }

    prepare() {
        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        }
    }

    destroy() {
        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        }
    }

    attach = () => {
        this.parent = document.querySelector('.Section-MainContent')
        if (!this.social) {
        }

        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)

        this.resize()
        this.render()
    }

    detach = () => {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleScroll() {
        this.render()
    }

    handleResize() {
        this.resize()
    }

    resize() {
        this.element.style.width = 'auto'

        const scrollTop = getScrollTop()

        const parentBox = this.parent.getBoundingClientRect()
        this.parentBox = {
            height: parentBox.height,
            width: parentBox.width,
            top: scrollTop + parentBox.top
        }

        const elementBox = this.element.getBoundingClientRect()
        this.elementBox = {
            height: elementBox.height,
            width: elementBox.width,
            top: scrollTop + elementBox
        }

        this.element.style.width = `${this.elementBox.width}px`
    }

    render() {
        const offset = getScrollTop() - this.parentBox.top + this.options.top
        const max = this.parentBox.height - this.elementBox.height

        if (offset < 0) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = 'translateY(0px)'
                this.element.style.opacity = '1'
            }
        } else if (offset > max) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = `translateY(${max}px)`
                this.element.style.opacity = '0'
            }
        } else {
            if (!this.isSticky) {
                this.element.classList.add(STATES.STICKY)
                this.isSticky = true
            }
            this.element.style.transform = `translateY(${offset}px)`
            this.element.style.opacity = '1'
        }

    }
}
