import Component from '../core/Component'

export default class PodcastLink extends Component {
    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        event.preventDefault()
        window.open(this.element.href, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=210')
    }
}
