import Component from '../core/Component';

export default class SampleComponent extends Component {
    constructor(element) {
        super(element);

        // this.ref = {
        //    test: null,
        //    items: [],
        // }
    }

    // async require() {
    //    this.throttle = await import('lodash/throttle');
    // }

    prepare() {
    }
}