import Component from '../core/Component';
import support from '../utils/BrowserSupport';
import { disableScrolling, enableScrolling } from '../services/Viewport';

export const STATUS = {
    VISIBLE: 'is-visible',
    ACTIVE: 'is-active'
}

export default class SearchLayer extends Component {

    constructor(element) {
        super(element);

        this.ref = {
            layer: null,
            background: null,
            closeButton: null,
        }

        //this.handleKeyDown = ::this.handleKeyDown;
    }

    prepare() {
        this.ref.layer.addEventListener('click', event => event.stopPropagation());
        this.ref.layer.addEventListener(support.transitionEnd, () => {
            if (!this.element.classList.contains(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE);
            }
        });
        this.ref.closeButton.addEventListener('click', ::this.handleCloseClick);
        this.ref.background.addEventListener('click', ::this.handleCloseClick);
    }

    /*destroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }*/

    /*handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close();
        }
    }*/

    handleCloseClick(event) {
        event.preventDefault();
        this.close();
    }

    open() {
        disableScrolling();
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.ACTIVE);
        const input = this.element.querySelector('.Typeahead-input');
        if (input) {
            input.focus();
        }
        //document.addEventListener('keydown', this.handleKeyDown);
    }

    close() {
        enableScrolling();
        this.element.classList.remove(STATUS.ACTIVE);
        //document.removeEventListener('keydown', this.handleKeyDown);
    }

    toggle() {
        if (this.is(STATUS.ACTIVE)) {
            this.close();
        } else {
            this.open();
        }
    }
}