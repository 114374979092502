import Component from '../core/Component'

const STATUS = {
    PINNED: 'is-pinned',
}

export default class ArticleBlending extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            overlay: null
        }

        this.handleScroll = ::this.handleScroll
        this.handleResize = ::this.handleResize
    }

    prepare() {
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleScroll, {
            passive: true
        })

        this.updateSizes()
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('scroll', this.handleScroll, {
            passive: true
        })
    }

    handleScroll() {
        this.togglePin()
    }

    handleResize() {
        this.updateSizes()
    }

    togglePin() {
        if (window.scrollY > this.containerRect.top && window.scrollY < this.containerRect.bottom) {
            this.pin()
        } else {
            this.unpin()
        }
    }

    pin() {
        this.ref.overlay.classList.add(STATUS.PINNED)
    }

    unpin() {
        this.ref.overlay.classList.remove(STATUS.PINNED)
    }

    updateSizes() {
        let headerHeight = document.getElementById('site-header').getBoundingClientRect().height

        this.containerRect = {
            top: this.element.getBoundingClientRect().top + window.scrollY - headerHeight - 50,
            bottom: this.element.getBoundingClientRect().bottom + window.scrollY - headerHeight
        }
    }
}