import Component from '../core/Component'

export default class Share extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault()
        const { open, name, params } = this.element.dataset
        window.open(open, name, params)
    }
}