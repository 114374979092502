import log from 'swup/lib/modules/log';
import Component from '../core/Component';
import Choices from 'choices.js'

export default class Select extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            select: null
        }
    }

    prepare() {
        const choices = new Choices(this.ref.select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
            placeholder: true,
        });

        if (this.element.hasAttribute("data-redirect")) {
            choices.passedElement.element.addEventListener('change', (event) => {
                const text = event.currentTarget.selectedOptions[0].text
                const categoriesLinks = document.querySelectorAll('.Sidebar--nav a')
                const current = Array.from(categoriesLinks).filter(el => {
                    return el.textContent.trim() === text
                })
                window.location.href = current[0].pathname
            });
        }
    }

}
