import Component from '../core/Component';
import EventBus, { EVENTS } from '../core/EventBus'

export const STATES = {
    HIDDEN: 'is-hidden'
}
export default class SearchOpener extends Component {

    constructor(element) {
        super(element)
    }

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault()

        EventBus.emit(EVENTS.searchToggle)
        this.element.parentElement.classList.add(STATES.HIDDEN)
    }
}
