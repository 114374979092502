import Component from '../core/Component';
import EventBus, { EVENTS } from '../core/EventBus'

export const STATES = {
    active: 'is-active',
}

export default class SidebarOpener extends Component {
    prepare() {
        this.element.addEventListener('click', :: this.handleClick)

        EventBus.on(EVENTS.sidebarOpened, this.handleSidebarOpen)
        EventBus.on(EVENTS.sidebarClosed, this.handleSidebarClose)
    }

    destroy() {
        EventBus.off(EVENTS.sidebarOpened)
        EventBus.off(EVENTS.sidebarClosed)
    }

    handleClick(event) {
        event.preventDefault()
        EventBus.emit(EVENTS.sidebarToggle, event.currentTarget)
    }

    handleSidebarClose = () => {
        this.element.classList.remove(STATES.active)

        console.log('close');

        // this.ref.items.forEach(item => item.classList.remove(STATUS.HIDDEN))
    }

    handleSidebarOpen = () => {
        this.element.classList.add(STATES.active)
    }
}
