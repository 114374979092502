// general
import Antispam from '../../components/Antispam'

// project
import Accordion from './Accordion'
import Animation from './Animation'
import ArticleBlending from './ArticleBlending'
import ArticleDetail from './ArticleDetail'
import Input from './Input'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Page from './Page'
import PodcastLink from './PodcastLink'
import SampleComponent from './SampleComponent'
import Scrollbar from './Scrollbar'
import Search from './Search'
import SearchLayer from './SearchLayer'
import SearchLayerOpener from './SearchLayerOpener'
import SearchOpener from './SearchOpener'
import Select from './Select'
import Share from './Share'
import Sidebar from './Sidebar'
import SidebarOpener from './SidebarOpener'
import SocialShare from './SocialShare'
import Sticky from './Sticky'
import ToggleHashtags from './ToggleHashtags'

const Components = {
    Accordion,
    Animation,
    Antispam,
    ArticleBlending,
    ArticleDetail,
    Input,
    Modal,
    ModalOpener,
    Page,
    PodcastLink,
    SampleComponent,
    // Scrollbar,
    Search,
    SearchLayer,
    SearchLayerOpener,
    SearchOpener,
    Select,
    Share,
    Sidebar,
    SidebarOpener,
    SocialShare,
    Sticky,
    ToggleHashtags,
}

export default Components
