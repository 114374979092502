import Component from '../core/Component'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'

Scrollbar.use(OverscrollPlugin)

export default class ScrollContainer extends Component {

    prepare() {
        this.scrollbar = Scrollbar.init(this.element, {
            renderByPixels: true,
            alwaysShowTracks: true
        })
    }
}