import Component from '../core/Component';
import EventBus, { EVENTS } from '../core/EventBus'

export const STATES = {
    active: 'is-active'
}

export default class Search extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            input: null
        }

        this.toggleHandler = () => this.toggle()
    }

    prepare() {
        EventBus.on(EVENTS.searchToggle, this.toggleHandler)
    }

    destroy() {
        EventBus.off(EVENTS.searchToggle, this.toggleHandler)
    }

    toggle() {
        if (this.element.classList.toggle(STATES.active)) {
            EventBus.emit(EVENTS.searchOpened)
            this.ref.input.focus()
            //
            const containerWrap = this.element.closest('.article-Layout-sidebar')
            const styles = `width:auto; min-width:480px; max-width:inherit;`
            containerWrap.style.cssText = styles
        } else {
            EventBus.emit(EVENTS.searchClosed)
        }
    }
}
