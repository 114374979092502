import Component from '../core/Component';

export default class SearchLayerOpener extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        this.layer = Component.getFromElement(document.getElementById('search-layer'));

        if (this.layer) {
            event.preventDefault();
            this.layer.toggle();
        }
    }

}