import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import Scroll, { scrollToElement } from '../services/Scroll'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const STATES = {
    ACTIVE: 'is-active',
    HIDDEN: 'is-hidden',
}

export default class Accordion extends Component {

    constructor(element) {
        super(element, {})

        this.ref = {
            subTitle: null,
            items: [],
            toggles: [],
            contents: []
        }

        this.options = {
            scrollTo: false,
            media: null
        }

        this.enquireHandler = {
            match: this.attach,
            unmatch: this.detach
        }

        if (this.element.dataset.media) {
            this.options.media = this.element.dataset.media
        }

        if (this.options.media) {
            this.options.media = queries?.[this.options.media] || this.options.media
        }
    }

    prepare() {
        if (this.options.media) {
            enquire.register(this.options.media, this.enquireHandler)
        } else {
            this.attach()
        }
    }

    destroy() {
        if (this.options.media) {
            enquire.unregister(this.options.media, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    attach = () => {
        this.ref.toggles.forEach(toggle => toggle.addEventListener('click', this.handleToggleClick))
        this.ref.contents.forEach(content => content.addEventListener(support.transitionEnd, this.handleContentTransitionEnd))
        this.ref.items.forEach((item, index) => this.close(index, true))
        Scroll.on('resize', this.handleResize)
    }

    detach = () => {
        this.ref.toggles.forEach(toggle => toggle.removeEventListener('click', this.handleToggleClick))
        this.ref.contents.forEach(content => {
            content.removeEventListener(support.transitionEnd, this.handleContentTransitionEnd)
            content.removeAttribute('style')
        })
        Scroll.off('resize', this.handleResize)
    }

    handleToggleClick = event => {
        event.preventDefault()
        event.currentTarget.classList.add(STATES.HIDDEN)
        event.currentTarget.remove()

        this.ref.subTitle.classList.add(STATES.ACTIVE)
        const index = this.ref.toggles.indexOf(event.currentTarget)
        this.toggle(index)
    }

    handleContentTransitionEnd = event => {
        const content = event.target
        const index = this.ref.contents.indexOf(content)
        const item = this.ref.items[index]

        if (this.options.scrollTo && item.classList.contains(STATES.ACTIVE)) {
            const box = item.getBoundingClientRect()
            if (box.top < 80 || box.top > 80 && window.innerHeight - box.bottom < 0) {
                scrollToElement(item, {
                    offset: 80
                })
            }
        }
    }

    handleResize = () => {
        this.resize()
    }

    toggle(itemIndex) {
        const item = this.ref.items[itemIndex]

        if (item.classList.contains(STATES.ACTIVE)) {
            this.close(itemIndex)
        } else {
            this.open(itemIndex)
        }
    }

    open(itemIndex) {
        const item = this.ref.items[itemIndex]
        const content = this.ref.contents[itemIndex]
        item.classList.add(STATES.ACTIVE)
        content.style.height = `calc(${content.scrollHeight}px + 100px)`

        this.ref.items.forEach((item, index) => {
            if (index !== itemIndex) {
                this.close(index)
            }
        })
    }

    close(itemIndex, force = false) {
        if (force || this.ref.items[itemIndex].classList.contains(STATES.ACTIVE)) {
            this.ref.items[itemIndex].classList.remove(STATES.ACTIVE)
            this.ref.contents[itemIndex].style.height = '0px'
        }
    }

    resize() {
        this.ref.items
            .map((element, index) => ({
                element,
                index
            }))
            .filter(item => item.element.classList.contains(STATES.ACTIVE))
            .map(item => this.ref.contents[item.index])
            .forEach(content => {
                content.removeAttribute('style')
                content.offsetWidth
                content.style.height = `${content.scrollHeight}px`
            })
    }
}
