// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';

import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import { USE_NATIVE_SCROLL, CMS, iOS, android } from './core/config'
import ScrollService from './services/Scroll'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import components from './components/';
import { loadComponents, removeComponents } from './core/componentUtils';

window.app = {
    components: components
};

import Swup from 'swup';
import swupGtmPlugin from 'swup/plugins/swupGtmPlugin';
import detectie from 'detectie';
import Sticky from 'sticky-js'

const IE = detectie()
const header = document.querySelector('#site-header').offsetHeight

if (!IE || IE > 11) {
    const swup = new Swup({
        LINK_SELECTOR: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([data-ref]), a[href^="/"]:not([data-no-swup]):not([data-rel="news-modal"]):not([data-component]):not([data-ref]), a[href^="#"]:not([data-no-swup]):not([data-component]):not([data-ref])',
        elements: ['#swup', '#modal-newsletter-swup'],
        animationSelector: '.Overlay',
        debugMode: false,
        doScrollingRightAway: false,
        animateScroll: true,
        preload: true,
        cache: true,
        plugins: [swupGtmPlugin]
    });

    // components
    swup.on('willReplaceContent', event => {
        document.querySelectorAll('[data-swup]').forEach(element => {
            removeComponents(element);
        });
    });

    swup.on('contentReplaced', event => {
        document.querySelectorAll('[data-swup]').forEach(element => {
            loadComponents(element);
        });
    });

    // scroll
    swup.on('willReplaceContent', event => {
        swup.options.animateScroll = false;
    });

    swup.on('contentReplaced', event => {
        Nette.reinit();
        if ($.nette) {
            $.nette.load();
        }
        var sticky = new Sticky('[data-sticky]', { "marginTop": header + 10 });
        setTimeout(() => {
            swup.options.animateScroll = true;
        });
    });

    swup.on('animationOutStart', event => {
        $('[data-component=Nav]').removeClass('is-open');
    });
}

var sticky = new Sticky('[data-sticky]', { "marginTop": header + 10, "stickyFor": 768 });

loadComponents();
