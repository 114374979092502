import * as ViewportServices from '../../services/Viewport';
import $ from 'jquery';


document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    const parentElement = element.parentNode

    let errorElement = null

    if (element.tagName.toLowerCase() != 'button') {
        errorElement = parentElement.querySelector('.InputError');
    }

    // proccesValidSpecialCases(element)

    element.classList.remove('has-error');
    element.classList.add('is-valid');

    if (!parentElement.querySelector('.has-error')) {
        parentElement.classList.remove('has-error')

        if (errorElement) {
            parentElement.removeChild(errorElement);
        }
    }
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    const parentElement = element.parentNode


    let errorElement = parentElement.querySelector('.InputError');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    if (element.tagName.toLowerCase() === 'select') {
        //select?
    }
    // proccesInvalidSpecialCases(element)

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    parentElement.appendChild(errorElement);
    parentElement.classList.add('has-error')
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});
document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.InputError');
    let $target = $error.closest('.FormItem');
    ViewportServices.scrollToElement($target.length ? $target : $form, -120);
});