import Component from '../core/Component'
import debounce from 'lodash/debounce'
import 'waypoints/lib/noframework.waypoints'

export default class ArticleDetail extends Component {
    constructor(element) {
        super(element)

        this.queue = []
        this.logStart = Date.now()
        this.readingSpeed = (1000 * 60) / 220 // 225 slov za minutu - cas pro jedno slovo v ms

        const words = parseInt(this.element.dataset.words)

        this.data = {
            articleId: this.element.dataset.id,
            words: isNaN(words) ? 0 : words,
        }

        this.ref = {
            content: null,
        }

        this.save = debounce(this.save, 200)
    }

    prepare() {
        const box = this.element.getBoundingClientRect()

        this.waypoints = [
            new Waypoint({
                element: this.element,
                offset: '50%',
                handler: (direction) => {
                    if (direction === 'down') {
                        this.push({
                            type: 'read',
                            time: Date.now(),
                            position: 0,
                            direction: direction,
                            ...this.data,
                        })
                    }
                },
            }),
            new Waypoint({
                element: this.element,
                offset: 0,
                handler: (direction) => {
                    if (direction === 'up') {
                        this.push({
                            type: 'read',
                            time: Date.now(),
                            position: 0,
                            direction: direction,
                            ...this.data,
                        })
                    }
                },
            }),
            new Waypoint({
                element: this.element,
                offset: window.innerHeight - (box.height * 1) / 4 + box.top,
                handler: (direction) => {
                    this.push({
                        type: 'read',
                        time: Date.now(),
                        position: 25,
                        direction: direction,
                        ...this.data,
                    })
                },
            }),
            new Waypoint({
                element: this.element,
                offset: window.innerHeight - (box.height * 2) / 4 + box.top,
                handler: (direction) => {
                    this.push({
                        type: 'read',
                        time: Date.now(),
                        position: 50,
                        direction: direction,
                        ...this.data,
                    })
                },
            }),
            new Waypoint({
                element: this.element,
                offset: window.innerHeight - (box.height * 3) / 4 + box.top,
                handler: (direction) => {
                    this.push({
                        type: 'read',
                        time: Date.now(),
                        position: 75,
                        direction: direction,
                        ...this.data,
                    })
                },
            }),
            new Waypoint({
                element: this.element,
                offset: 'bottom-in-view',
                handler: (direction) => {
                    if (direction == 'down') {
                        // cas na clanek
                        const delay = this.readingSpeed * this.data.words

                        setTimeout(
                            () => {
                                this.push({
                                    type: 'read',
                                    time: Date.now(),
                                    position: 100,
                                    direction: direction,
                                    ...this.data,
                                })
                            },
                            Date.now() - this.logStart < 100 ? delay : 0,
                        )
                    }
                },
            }),
        ]
    }

    destroy() {
        this.waypoints.forEach((waypoint) => waypoint.destroy())
    }

    push(value) {
        this.queue.push(value)
        this.save()
    }

    save = () => {
        const currentQueue = this.queue.slice()
        this.queue = []
        $.ajax({
            url: '',
            data: {
                triggers: currentQueue,
            },
        })
    }
}
