import Component from '../core/Component';
import WOW from 'wow.js';

export default class Animation extends Component {
    prepare() {
        this.wow = new WOW(
            {
                offset: 50
            }
        );

        this.wow.init();
    }
}