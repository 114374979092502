import Component from '../core/Component';

export const STATUS = {
    OPEN: 'is-open',
}

export default class SocialShare extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            toggle: null
        }

        this.isOpen = false
    }

    prepare() {
        this.element.addEventListener('mouseover', ::this.handleHover)
        this.element.addEventListener('mouseout', ::this.handleExit)
        this.ref.toggle.addEventListener('click', ::this.handleClick)
    }

    handleHover(event) {
        this.element.classList.add(STATUS.OPEN)
        this.isOpen = true
    }

    handleExit(event) {
        this.element.classList.remove(STATUS.OPEN)
        this.isOpen = false
    }

    handleClick(event) {
        if (this.isOpen) {
            this.element.classList.remove(STATUS.OPEN)
            this.isOpen = false
        } else {
            this.element.classList.add(STATUS.OPEN)
            this.isOpen = true
        }
    }
}