import Component from '../core/Component';

const STATUS = {
    FILLED: 'is-filled'
}

export default class Input extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            input: null,
            label: null
        }
    }

    prepare() {
        this.ref.input.addEventListener('focusout', :: this.handleFocus)
        this.ref.input.addEventListener('change', :: this.handleFocus)
        this.handleClearTextarea()
        this.handleFilled()
    }

    handleFilled() {
        if (this.ref.input.value.length > 0) {
            this.element.classList.add(STATUS.FILLED)
        }
    }

    handleClearTextarea() {
        if (this.ref.input.type == 'textarea') {
            this.ref.input.value = ''
        }
    }

    handleFocus(event) {
        if (event.target.value.length == 0) {
            this.element.classList.remove(STATUS.FILLED)
        } else {
            this.element.classList.add(STATUS.FILLED)
        }
    }
}
